import React from "react"
import Layout, { Main } from "../wrappers/layout"

export default function NotFoundPage({ data }) {

  return (
    <Layout>
			<Main className="page-gutters outerx2 flex-center">
				<h1>Page not found</h1>
			</Main>
    </Layout>
  )
}

export { Meta as Head } from "../components/meta"