import React from "react"
import { sortCollectionMetafields, useProductMetafields } from "./metafields"
import { SWATCHES } from "./constants"
import useSettings from "../hooks/useSettings"

export function Meta({ 
	backgroundColor="#FFFFFF",
	foregroundColor="#000000",
	footerForegroundColour,
	backgroundImageSrc,
	...props
}) {

	const { 
		siteTitle, 
		siteDescription, 
		defaultShareImage 
	} = useSettings()

	const title = props.title || siteTitle
	const description = props.description || siteDescription.text
	const imageSrc = props.imageSrc || `https:${defaultShareImage.file.url}`

	return (
		<>
			<title>{title}</title>
			<meta name="description" content={description} />
			{/* Facebook */}
			<meta property="og:url" content="https://www.bard-scotland.com/" />
			<meta property="og:type" content="website" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={imageSrc} />
			{/* Twitter */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta property="twitter:domain" content="bard-scotland.com" />
			<meta property="twitter:url" content="https://www.bard-scotland.com/" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={imageSrc} />
			{/* Verification */}
			<meta name="facebook-domain-verification" content="8yuxbz8zps1xbzre4v95o1qq1g8p6s" />
			{/* Style */}
			<style>
				{`
					:root {
						--background: ${backgroundColor};
						--foreground: ${foregroundColor};
						--footer-foreground: ${footerForegroundColour || foregroundColor};
					}

					${backgroundImageSrc ? `
						html {
							background-image: url(${backgroundImageSrc});
							background-repeat: no-repeat;
							background-size: cover;
							background-attachment: fixed;
							background-position: center top;
						}
					` : ``}
				`}
			</style>
		</>
	)
}

export function HomepageMeta ({ data }) {
	const { backgroundColour, foregroundColour, backgroundImage, footerForegroundColour } = data?.homepage?.nodes?.[0] || {}
	return (
		<Meta 
			backgroundImageSrc={backgroundImage?.file?.url}
			foregroundColor={foregroundColour} 
			backgroundColor={backgroundColour}
			footerForegroundColour={footerForegroundColour}
		/>
	)
}

export function CollectionMeta ({ data }) {
	const { backgroundColor } = sortCollectionMetafields(data?.collection?.metafields)
	// Todo: Title, description, share image
	return <Meta backgroundColor={backgroundColor} />
}

export function MakerPageMeta ({ data }) {
	// Todo: Title, description, share image
	return <Meta backgroundColor={SWATCHES[data?.maker?.backgroundColor || "White"]} />
}

export function MakersMeta () {
	// Todo: Title, description, share image
	return <Meta />
}

export function ArticleMeta ({ data }) {
	// Todo: Title, description, share image
	const { title, description, backgroundColor } = data?.article
	return <Meta title={`${title} | Bard`} description={description?.text} backgroundColor={SWATCHES[backgroundColor]} />
}

export function AboutMeta ({ data }) {
	// Todo: Title, description, share image
	const { title, description, backgroundColor } = data?.page || {}
	return <Meta title={`${title} | Bard`} description={description?.text} backgroundColor={SWATCHES[backgroundColor]} />
}

export function StoriesMeta ({ data }) {
	// Todo: Title, description, share image
	const { backgroundColor } = data?.page
	return <Meta backgroundColor={SWATCHES[backgroundColor]} />
}

export function ProductPageMeta ({ data }) {

	const { title, vendor, media } = data?.product || {}

	const {
		product_title,
		seo_title,
		seo_description,
	} = useProductMetafields(data?.product?.metafields)

	const productTitle = seo_title || product_title || title?.replace(vendor, "");

	return (
		<Meta 
			title={`${productTitle || 'View product'} | Bard`}
			description={seo_description}
			imageSrc={media?.[0]?.originalSrc}
		/>
	)
}