import { useState } from "react";
import { arrayToObject } from "./helpers";

// Helper function we can use in gatsby-node.js
export function sortCollectionMetafields (metafields) {
	return {
		subCollections: metafields?.find(m => m.linkedCollections?.length > 0)?.linkedCollections?.map(item => {
			return {
				to: `/shop/${item.handle}`,
				...item
			}
		}),
		backgroundColor: metafields?.find(m => m.key === "background_colour")?.value || "#FFFFFF"
	}
}

export function sortProductMetafields(metafields=[]) {
	return arrayToObject(metafields, 'key', item => item.value)
}

// Wrapped in state for components
export function useProductMetafields(metafields) {
	const [fields] = useState(
		sortProductMetafields(metafields)
	)
	return fields
}

export function useCollectionMetafields(metafields) {
	const [{ subCollections, backgroundColor }] = useState(
		sortCollectionMetafields(metafields)
	)
	
	return {
		subCollections,
		backgroundColor
	}
}